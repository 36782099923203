<p-dropdown
  styleClass="{{ 'address-search ' + styleClass }}"
  [options]="(filteredAddresses | async) ?? []"
  optionLabel="full_address"
  id="address"
  name="address"
  #address="ngModel"
  [(ngModel)]="selectedAddress"
  [editable]="true"
  [required]="required"
  scrollHeight="15rem"
  (onChange)="changeInput($event)"
>
  <ng-template pTemplate="selectedItem">
    <span>{{ location.shortenAddress }}</span>
  </ng-template>

  <ng-template pTemplate="empty">{{
    "noSearchResults" | translation : componentName
  }}</ng-template>
</p-dropdown>
