import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, debounceTime, Subject, Subscription } from 'rxjs';

import { DropdownModule } from 'primeng/dropdown';

import { environment } from '@environments/environment';
import { TranslationPipe } from '@pipes/translation.pipe';
import { Location } from '@models/location';
import { RestApiClient } from '@models/rest.api-client';
import { ServerError } from '@models/server-error';
import { Geocode, Properties } from '@interfaces/location';
import { ErrorHandlerService } from '@services/error-handler.service';

@Component({
  selector: 'address-search',
  standalone: true,
  imports: [FormsModule, AsyncPipe, TranslationPipe, DropdownModule],
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressSearchComponent),
      multi: true,
    },
  ],
})
export class AddressSearchComponent implements OnInit, OnDestroy {
  public readonly componentName: string = 'AddressSearchComponent';
  private onTouched: () => void = () => {};
  private onChange: (value: any) => void = () => {};
  @Input() styleClass: string = '';
  @Input() public disabled: boolean = false;
  @Input() public required: boolean = false;
  @Output() public onSelectAddress: EventEmitter<Location> =
    new EventEmitter<Location>();
  public location!: Location;
  public selectedAddress!: Properties;
  public filteredAddresses: BehaviorSubject<Array<Properties>> =
    new BehaviorSubject<Array<Properties>>([]);
  private addressSearch$: Subject<any> = new Subject<any>();
  private subscription: Subscription = Subscription.EMPTY;
  private restApiClient: RestApiClient;

  constructor(private errorHandlerService: ErrorHandlerService) {
    this.location = new Location();
    this.restApiClient = new RestApiClient();
  }

  public ngOnInit(): void {
    this.subscription = this.addressSearch$
      .pipe(debounceTime(500))
      .subscribe((event) => this.fetchAddresses(event));
  }

  // Implementing ControlValueAccessor methods
  public writeValue(value: any): void {
    this.selectedAddress = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private async fetchAddresses(event: any): Promise<void> {
    if (!event.value) {
      return;
    }

    try {
      const locations = await this.restApiClient
        .method('GET')
        .baseUrl('https://api.mapbox.com/search/geocode/v6/forward')
        .params({
          access_token: environment.ACCESS_TOKEN_MAPBOX,
          autocomplete: 'true',
          bbox: environment.BBOX,
          country: environment.COUNTRY,
          fuzzyMatch: 'true',
          language: 'bs',
          proximity: environment.PROXIMITY,
          routing: 'false',
          q: event.value,
          types: environment.TYPES,
        })
        .headers({ Accept: 'application/vnd.geo+json' })
        .response<Geocode>();

      this.filteredAddresses.next(
        locations.features.map((location) => location.properties)
      );
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }

  public changeInput(event: any): void {
    this.addressSearch$.next(event);

    if (typeof this.selectedAddress === 'string') {
      this.location.address = this.selectedAddress;
      return;
    }

    this.selectedAddressChange();
  }

  private selectedAddressChange(): void {
    this.location.address = this.selectedAddress.full_address;
    this.location.street = this.selectedAddress.context.street.name;
    this.location.postcode = this.selectedAddress.context.postcode.name;
    this.location.place = this.selectedAddress.context.place.name;
    this.location.region = this.selectedAddress.context.region.name;
    this.location.country = this.selectedAddress.context.country.name;
    this.location.countryCode =
      this.selectedAddress.context.country.country_code_alpha_3;

    this.onChange(this.location.shortenAddress); // Notify the parent form of the change
    this.onTouched(); // Mark the control as touched

    this.onSelectAddress.emit(this.location);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
