import { Location as LocationInterface } from '@interfaces/location';

export class Location implements LocationInterface {
  private _address: string;
  private _street: string;
  private _postcode: string;
  private _place: string;
  private _region: string;
  private _country: string;
  private _countryCode: string;

  constructor() {
    this._address = '';
    this._street = '';
    this._postcode = '';
    this._place = '';
    this._region = '';
    this._country = '';
    this._countryCode = '';
  }

  public get address(): string {
    return this._address;
  }

  public get street(): string {
    return this._street;
  }

  public get postcode(): string {
    return this._postcode;
  }

  public get place(): string {
    return this._place;
  }

  public get region(): string {
    return this._region;
  }

  public get country(): string {
    return this._country;
  }

  public get countryCode(): string {
    return this._countryCode;
  }

  public get shortenAddress(): string {
    return `${this._street}, ${this._postcode} ${this._place}`;
  }

  public set address(address: string) {
    this._address = address;
  }

  public set street(street: string) {
    this._street = street;
  }

  public set postcode(postcode: string) {
    this._postcode = postcode;
  }

  public set place(place: string) {
    this._place = place;
  }

  public set region(region: string) {
    this._region = region;
  }

  public set country(country: string) {
    this._country = country;
  }

  public set countryCode(countryCode: string) {
    this._countryCode = countryCode;
  }
}
